.problemSlider {
	width: 100%;
	padding-top: 60px;
	padding-bottom: 40px;
	color: #000;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	background-color: #f9f9f9;

	.inner {
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			text-align: center;
			font-weight: 400;
			font-size: 24px;
		}

		.line {
			margin: auto;
			margin-bottom: 65px;
			margin-top: 5px;
			width: 180px;
			bottom: -5px;
			height: 2px;
			background-color: #d60623;
		}
		.sliderContainer {
			max-width: 1030px;

			.btnPrev {
				cursor: pointer;
				width: 20px;
				height: 32px;
				transform: rotate(180deg);
			}
			.btnNext {
				cursor: pointer;
				width: 20px;
				height: 32px;
			}
		}
	}
}

@media (max-width: 1240px) {
	.problemSlider {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #000;
		font-family: Roboto, Helvetica, Arial, sans-serif;
		background-color: #f9f9f9;

		.inner {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				text-align: center;
				font-weight: 400;
				font-size: 18px;
			}

			.line {
				margin: auto;
				margin-bottom: 15px;
				margin-top: 5px;
				width: 180px;
				bottom: -5px;
				height: 2px;
				background-color: #d60623;
			}
			.sliderContainer {
				max-width: 768px;
			}
		}
	}
}

@media (max-width: 767px) {
	.problemSlider {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #000;
		font-family: Roboto, Helvetica, Arial, sans-serif;
		background-color: #f9f9f9;

		.inner {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				text-align: center;
				font-weight: 400;
				font-size: 18px;
				padding: 0 5px;
			}

			.line {
				margin: auto;
				margin-bottom: 15px;
				margin-top: 5px;
				width: 180px;
				bottom: -5px;
				height: 2px;
				background-color: #d60623;
			}
			.sliderContainer {
				max-width: 320px;
			}
		}
	}
}
