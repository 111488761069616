.topScreenVideo {
	position: relative;
	padding: 0;
	top: 118px;
	color: #fff;
	background-color: #414141;
	background-size: cover;
	background-position: center top;
	font-family: Roboto, Helvetica, Arial, sans-serif;

	.containerVideo {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		.backgroundVideo {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
 
.inner {
	position: relative;
	width: 100%;
	margin: 0 auto;
	min-width: 320px;
	padding: 0 60px;
	max-width: 1440px;

	.wrapper {
		width: 1080px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		.header {
			flex: 1 1 auto;
			margin-top: 105px;
			margin-right: 55px;

			.title {
				font-size: 48px;
				text-align: left;
				margin: 0 auto 10px;
				font-weight: 500;
				line-height: 1.15;
			}
			.subtitle {
				font-size: 26px;
				text-align: left;
				margin-top: 5px;
				margin-bottom: 20px;
				font-weight: 400;
				line-height: 1.15;

				.struck {
					text-decoration: line-through;
				}
			}
		}
		.topScreenForm {
			position: relative;
			flex: 0 0 348px;
			padding: 105px 25px;
			background: rgba(170, 170, 170, 0.72);
			width: 100%;

			.title {
				font-size: 20px;
				text-align: left;
				margin-bottom: 5px;
				font-weight: 400;
				line-height: 1.2;
			}

			.subtitle {
				font-size: 18px;
				text-align: left;
				margin: -5px auto 10px;
				font-weight: 400;
				line-height: 1.2;
			}

			.inputs {
				color: #fff;

				.input {
					padding: 18px;
					margin-bottom: 12px;
					font-size: 14px;
					width: 100%;
					color: #000;
					font-weight: 400;
					border-radius: 2px;
					background-color: #fff;
					border: none;
				}
				.submit {
					margin-bottom: 18px;
					padding: 14px 20px;
					font-size: 16px;
					width: 100%;
					position: relative;
					display: flex;
					justify-content: center;
					background-color: #d60623;
					cursor: pointer;
					text-decoration: none;
					border: 1px solid transparent;
					transition: all 0.3s ease;
					box-shadow: 5px 2px 27px rgb(215 0 0 / 50%);
					color: #fff;
					font-weight: 700;
					text-align: center;
					border-radius: 5px;
					text-transform: uppercase;
					margin-top: 10px;
				}
				.submit:hover {
					background-color: #950316;
				}
				.submit:active {
					background-color: #950316;
				}

				.check {
					margin-top: 20px;
					font-size: 14px;
					line-height: 1.42857143;
					color: #333;

					.checkbox {
						position: absolute;
						width: 10px;
						height: 10px;
						left: 28px;
						bottom: 205px;
						z-index: -3;
					}

					.text {
						position: relative;
						display: inline-block;
						width: 100%;
						padding-left: 24px;
						font-size: 12px;
						border-radius: 3px;
						font-weight: 400;
						cursor: pointer;
						color: #fff;
						line-height: 1.3;

						.link {
							color: #d60623;
						}
					}

					.checkboxStyleActive {
						content: '';
						position: absolute;
						width: 14px;
						height: 14px;
						top: 368px;
						left: 26px;
						border: 1px solid #d60724;
						border-radius: 2px;
						background-color: #fff;
						background-image: url(../../assets/images/icons/galka.svg);
						background-size: contain;
						transition: background-color 0.3s ease;
					}
					.checkboxStyle {
						content: '';
						position: absolute;
						width: 14px;
						height: 14px;
						top: 368px;
						left: 26px;
						border: 1px solid #d60724;
						border-radius: 2px;
						background-color: #fff;
						background-image: none;
						background-size: contain;
						transition: background-color 0.3s ease;
					}
				}
			}
		}
		.mobileAppeal {
			display: none;
		}
	}
}

@media (max-width: 1240px) {
	.topScreenVideo {
		top: 70px;
	}

	.inner {
		padding: 0 20px;
		max-width: 900px;

		.wrapper {
			width: 648px;
			margin: 0 auto;

			.header {
				flex: 0 0 300px;
				margin-top: 50px;
				margin-right: 0;

				.title {
					font-size: 26px;
				}

				.subtitle {
					font-size: 16px;
				}
			}

			.topScreenForm {
				flex: 0 0 300px;
				padding: 50px 20px;

				.title {
					font-size: 16px;
				}

				.subtitle {
					font-size: 16px;
				}

				.inputs {
					color: #fff;

					.input {
						padding: 16px;
						margin-bottom: 10px;
						font-size: 12px;
					}
					.submit {
						margin-bottom: 14px;
						padding: 12px 20px;
						font-size: 14px;
					}
					.submit:hover {
						background-color: #950316;
					}
					.submit:active {
						background-color: #950316;
					}

					.check {
						margin-top: 20px;
						font-size: 14px;
						line-height: 1.42857143;
						color: #333;

						.checkbox {
							left: 25px;
							bottom: 118px;
						}

						.text {
							position: relative;
							display: inline-block;
							width: 100%;
							padding-left: 24px;
							font-size: 10px;
							border-radius: 3px;
							font-weight: 400;
							cursor: pointer;
							color: #fff;
							line-height: 1.3;

							.link {
								color: #d60623;
							}
						}

						.checkboxStyleActive {
							content: '';
							position: absolute;
							width: 12px;
							height: 12px;
							top: 280px;
							left: 24px;
							border: 1px solid #d60724;
							border-radius: 2px;
							background-color: #fff;
							background-image: url(../../assets/images/icons/galka.svg);
							background-size: contain;
							transition: background-color 0.3s ease;
						}
						.checkboxStyle {
							content: '';
							position: absolute;
							width: 12px;
							height: 12px;
							top: 280px;
							left: 24px;
							border: 1px solid #d60724;
							border-radius: 2px;
							background-color: #fff;
							background-image: none;
							background-size: contain;
							transition: background-color 0.3s ease;
						}
					}
				}
			}
			.mobileAppeal {
				display: none;
			}
		}
	}
}

@media (max-width: 768px) {
	.topScreenVideo {
		top: 70px;
		padding-bottom: 36px;
		max-width: none;
		margin: 0;
	}

	.inner {
		min-width: 320px;
		padding: 0 10px;
		max-width: 480px;

		.wrapper {
			width: 100%;
			justify-content: center;
			flex-direction: column;

			.header {
				margin-bottom: 40px;
				flex: auto;

				.title {
					font-size: 24px;
					text-align: center;
				}

				.subtitle {
					font-size: 16px;
					text-align: center;

					.struck {
						text-decoration: line-through;
					}
				}
			}

			.topScreenForm {
				display: none;
			}
			.mobileAppeal {
				display: block;
				padding-top: 60px;

				.title {
					margin-bottom: 10px;
					font-size: 16px;
					font-weight: 400;
					line-height: 1.2;
					text-align: center;
				}
				.button {
					width: 90%;
					max-width: 294px;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #d60623;
					cursor: pointer;
					text-decoration: none;
					border: 1px solid transparent;
					transition: all 0.3s ease;
					box-shadow: 5px 2px 27px rgb(215 0 0 / 50%);
					padding: 12px 20px;
					margin: 0 auto;
					color: #fff;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					text-align: center;
					border-radius: 5px;
				}
			}
		}
	}
}
