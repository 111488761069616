.pricesList {
	padding-top: 60px;
	padding-bottom: 0px;
	color: #000;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	line-height: 1.4;

	.inner {
		max-width: 1440px;
		padding: 0 120px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;

		.content {
			.titleContainer {
				margin-bottom: 40px;
				text-align: center;

				.title {
					font-size: 24px;
					position: relative;
					display: inline-block;
					margin: 0;
					font-weight: 400;
				}
				.title::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 50%;
					transform: translateX(-50%);
					background-color: #d60623;
					width: 180px;
					bottom: -5px;
				}
			}
			.table {
				margin: 0 auto 40px;
				width: 100%;
				max-width: 1080px;

				.row {
					display: flex;
					overflow: hidden;
					align-items: center;
					justify-content: space-between;
					flex-flow: row nowrap;
					position: relative;
					margin-bottom: 40px;

					.cell1 {
						position: relative;
						font-weight: 500;
						line-height: 1.15;
						background-color: #fff;
						font-size: 20px;
						max-width: 75%;
						padding-left: 22px;
						padding-right: 12px;

						.subtitle {
							font-weight: 300;
							font-size: 16px;
						}
					}

					.cell1::before {
						position: absolute;
						content: '';
						width: 10px;
						height: 10px;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
						background-color: #d60623;
						box-shadow: 0 2px 4px rgb(215 0 0 / 50%);
						z-index: 2;
					}

					.cell2 {
						position: relative;
						font-weight: 500;
						line-height: 1.15;
						background-color: #fff;
						font-size: 20px;
						padding-left: 12px;
						text-align: right;
					}
				}
				.row::before {
					content: '';
					position: absolute;
					width: 100%;
					left: -14px;
					top: 44%;
					z-index: -1;
					height: 14px;
					background-repeat: repeat-x;
					background-image: url(../../assets/images/bg-pattern_s.png);
				}
			}
		}
	}
}

@media (max-width: 1240px) {
	.pricesList {
		padding-top: 24px;
		padding-bottom: 0px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.content {
				.titleContainer {
					.title {
						font-size: 18px;
					}

					.title::after {
						width: 120px;
					}
				}
				.table {
					max-width: 648px;

					.row {
						margin-bottom: 20px;

						.cell1 {
							font-size: 13px;
							padding-left: 15px;
							padding-right: 6px;

							.subtitle {
								font-size: 10px;
							}
						}

						.cell1::before {
							width: 7px;
							height: 7px;
						}

						.cell2 {
							font-size: 13px;
							padding-left: 6px;
							text-align: right;
						}
					}
					.row::before {
						background-image: url(../../assets/images/bg-pattern_s.png);
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.pricesList {
		.inner {
			max-width: 480px;
			padding: 0 10px;
		}
	}
}
