$primary-color: #d60623;

.quiz {
	width: 100%;
	.containerForm {
		margin-top: 60px;
		margin-bottom: 80px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		align-items: center;
		font-family: Roboto, Helvetica, Arial, sans-serif;
		color: #000;

		.step {
			width: auto;
			padding-bottom: 54px;
			outline: 0;
			float: none;
			height: 100%;
			min-height: 1px;

			.final {
				padding: 0 10px;

				.title {
					text-align: center;
					font-weight: 500;
					line-height: 1.2;
					font-size: 24px;
					margin-bottom: 30px;
				}
				.phone {
					box-shadow: 0 2px 4px rgb(74 74 74 / 50%);
					margin: 0 auto 24px;
					font-size: 14px;
					margin-bottom: 32px;
					width: 300px;

					.input {
						display: block;
						width: 100%;
						padding: 18px 20px;
						border: none;
						border-radius: 2px;
					}
				}
				.button {
					cursor: pointer;
					text-transform: uppercase;
					border: none;
					border-radius: 2px;
					background-color: $primary-color;
					color: #fff;
					font-weight: 700;
					margin: 0 auto;
					display: block;
					font-size: 14;
					min-width: 240px;
					max-width: none;
					padding: 10px 30px;
				}
			}
		}
		.inner {
			.quizTitle {
				margin-bottom: 40px;
				text-align: center;

				.title {
					font-size: 24px;
					display: inline-block;
					margin: 0;
					color: #000;
					font-weight: 400;
				}
				.line {
					margin: 10px auto;
					width: 180px;
					height: 2px;
					background-color: $primary-color;
				}
			}
		}
		.slider {
			.step {
				.container {
					margin-bottom: 20px;
					.question {
						font-weight: 500;
						font-size: 24px;
						text-align: center;
						margin-bottom: 0px;
					}
					.remark {
						font-weight: 300;
						font-size: 10px;
						text-align: center;
						margin-bottom: 25px;
					}
					.answer {
						cursor: pointer;
						width: auto;
						font-size: 24px;
						margin-bottom: 16px;
						position: relative;
						margin-bottom: 8px;
						border-radius: 7px;
						box-shadow: 0 1px 2px rgb(74 74 74 / 50%);
						background-color: #fff;
						padding: 15px 40px;

						.label {
							display: flex;
							cursor: pointer;
							font-weight: 500;
							position: relative;
							overflow: hidden;
							margin-bottom: 0.375em;

							.input {
								position: absolute;
								left: -9999px;
								&:checked + .span {
									background-color: mix(#fff, $primary-color, 84%);
									&:before {
										box-shadow: inset 0 0 0 0.4375em $primary-color;
									}
								}
							}
							.span {
								display: flex;
								align-items: center;
								padding: 0.375em 0.75em 0.375em 0.375em;
								border-radius: 99em;
								transition: 0.25s ease;
								&:hover {
									background-color: mix(#fff, $primary-color, 84%);
								}
								&:before {
									display: flex;
									flex-shrink: 0;
									content: '';
									background-color: #fff;
									width: 1.5em;
									height: 1.5em;
									border-radius: 50%;
									margin-right: 0.375em;
									transition: 0.25s ease;
									box-shadow: inset 0 0 0 0.125em $primary-color;
								}
							}
						}
					}
				}
			}
		}
	}
	.innerControl {
		.controls {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			width: auto;

			.btnPrev {
				cursor: pointer;
				width: 132px;
				height: 40px;
				font-size: 14px;
				font-weight: 600;
				color: $primary-color;
				background-color: #fff;
				border: 1px solid $primary-color;
				border-radius: 1px;
			}
			.status {
				font-size: 20px;
				font-weight: 300;
				text-align: center;
				margin: 0 20px;
			}
			.btnNextActive {
				cursor: pointer;
				width: 132px;
				height: 40px;
				font-size: 14px;
				font-weight: 600;
				color: #fff;
				background-color: $primary-color;
				border-radius: 1px;
				border: none;
			}
			.btnNext {
				cursor: default;
				width: 132px;
				height: 40px;
				font-size: 14px;
				font-weight: 600;
				color: #fff;
				background-color: #6a6a6a;
				border-radius: 1px;
				border: none;
			}
		}
	}
	.progress {
		position: relative;
		margin-top: 15px;
		width: 100%;
		height: 2px;
		background-color: #c4c4c4;

		.progressBar {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #d60623;
		}
	}
}

@media (max-width: 1240px) {
	.containerForm {
		margin-top: 40px !important;
		margin-bottom: 60px !important;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		align-items: center;
		font-family: Roboto, Helvetica, Arial, sans-serif;
		color: #000;

		.inner {
			.quizTitle {
				.title {
					font-size: 18px !important;
				}
			}
		}
		.slider {
			.step {
				.container {
					.question {
						font-size: 16px !important;
					}
					.answer {
						padding: 10px 20px !important;
						font-size: 14px !important;
						width: 480px;
					}
				}
			}
		}
		.innerControl {
			.controls {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: nowrap;
				width: auto;

				.btnPrev {
					cursor: pointer;
					width: 120px;
					height: 40px;
					font-size: 14px;
					font-weight: 600;
					color: $primary-color;
					background-color: #fff;
					border: 1px solid $primary-color;
					border-radius: 1px;
				}
				.status {
					font-size: 14px;
					font-weight: 300;
					text-align: center;
				}
				.btnNextActive {
					cursor: pointer;
					width: 120px;
					height: 40px;
					font-size: 14px;
					font-weight: 600;
					color: #fff;
					background-color: $primary-color;
					border-radius: 1px;
					border: none;
				}
				.btnNext {
					cursor: pointer;
					width: 120px;
					height: 40px;
					font-size: 14px;
					font-weight: 600;
					color: #fff;
					background-color: #6a6a6a;
					border-radius: 1px;
					border: none;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.quiz {
		width: 100%;
		.containerForm {
			margin: 5px;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: stretch;
			margin-top: 30px;
			margin-bottom: 40px;

			.inner {
				.quizTitle {
					margin-bottom: 20px;
					text-align: center;

					.title {
						font-size: 18px;
						display: inline-block;
						margin: 0;
						color: #000;
						font-weight: 400;
					}
					.line {
						margin: 10px auto;
						width: 180px;
						height: 2px;
						background-color: $primary-color;
					}
				}
			}
			.slider {
				.step {
					width: auto;
					float: none;

					.container {
						margin-bottom: 30px;
						.question {
							font-size: 16px;
							text-align: center;
							margin-bottom: 0px;
						}
						.remark {
							font-weight: 200;
							font-size: 10px;
							text-align: center;
							margin-bottom: 15px;
						}
						.answer {
							cursor: pointer;
							width: auto;
							padding: 10px 40px;
							font-size: 14px;
							position: relative;
							margin-bottom: 8px;
							border-radius: 7px;
							box-shadow: 0 1px 2px rgb(74 74 74 / 50%);
							background-color: #fff;
							margin-top: 0;

							.label {
								display: flex;
								cursor: pointer;
								font-weight: 500;
								position: relative;
								overflow: hidden;
								margin-bottom: 0.375em;

								.input {
									position: absolute;
									left: -9999px;
									&:checked + .span {
										background-color: mix(#fff, $primary-color, 84%);
										&:before {
											box-shadow: inset 0 0 0 0.4375em $primary-color;
										}
									}
								}
								.span {
									display: flex;
									align-items: center;
									padding: 0.375em 0.75em 0.375em 0.375em;
									border-radius: 99em; // or something higher...
									transition: 0.25s ease;
									&:hover {
										background-color: mix(#fff, $primary-color, 84%);
									}
									&:before {
										display: flex;
										flex-shrink: 0;
										content: '';
										background-color: #fff;
										width: 1.5em;
										height: 1.5em;
										border-radius: 50%;
										margin-right: 0.375em;
										transition: 0.25s ease;
										box-shadow: inset 0 0 0 0.125em $primary-color;
									}
								}
							}
						}
					}
				}
			}
		}
		.innerControl {
			.controls {
				margin: 5px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: nowrap;
				width: auto;
				.btnPrev {
					cursor: pointer;
					width: 100px;
					height: 40px;
					font-size: 14px;
					font-weight: 600;
					color: $primary-color;
					background-color: #fff;
					border: 1px solid $primary-color;
					border-radius: 1px;
				}
				.status {
					font-size: 12px;
					font-weight: 300;
					text-align: center;
				}
				.btnNextActive {
					cursor: pointer;
					width: 100px;
					height: 40px;
					font-size: 14px;
					font-weight: 600;
					color: #fff;
					background-color: $primary-color;
					border-radius: 1px;
					border: none;
				}
				.btnNext {
					cursor: pointer;
					width: 100px;
					height: 40px;
					font-size: 14px;
					font-weight: 600;
					color: #fff;
					background-color: #6a6a6a;
					border-radius: 1px;
					border: none;
				}
			}
		}
	}
}
