.steps {
	background-image: url(../../assets/images/steps/man_bg.jpg);
	width: 100%;
	padding-top: 60px;
	padding-bottom: 80px;
	color: #fff;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	background-color: #656565;
	background-size: cover;

	.inner {
		position: relative;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;
		max-width: 1440px;
		padding: 0 120px;

		.titleContainer {
			margin-bottom: 60px;
			text-align: center;

			.title {
				position: relative;
				display: inline-block;
				margin: 0;
				font-family: Roboto, Helvetica, Arial, sans-serif;
				color: #fff;
				font-size: 24px;
				font-weight: 400;
			}
			.title::after {
				content: '';
				position: absolute;
				width: 180px;
				height: 2px;
				bottom: -5px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				background-color: #d60623;
			}
		}

		.row {
			max-width: 1200px;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-moz-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			margin: 0 auto 24px;
			margin-bottom: 140px;

			.col {
				width: 47.5%;
				-webkit-box-flex: 0;
				-moz-box-flex: 0;
				-ms-flex: 0 0 auto;
				flex: 0 0 auto;
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-moz-box-orient: vertical;
				-moz-box-direction: normal;
				-ms-flex-flow: column nowrap;
				flex-flow: column nowrap;
				-webkit-box-pack: start;
				-moz-box-pack: start;
				-ms-flex-pack: start;
				justify-content: flex-start;

				.imgWrapper {
					margin: 0;
					position: relative;
					width: 100%;

					.imageContainer {
						width: 100%;
						padding-top: 56.25%;
						background-size: cover;

						.img {
							position: absolute;
							left: 0;
							top: 0;
							width: auto;
							max-width: 100%;
							height: auto;
							border-radius: 5px;
						}
					}
				}
				.subtitle {
					font-weight: 500;
					margin: 0 0 20px;
					font-size: 24px;
					margin-bottom: 25px;
				}

				.list {
					position: relative;
					margin: 0 auto;
					padding: 0;
					list-style: none;

					.item {
						color: #fff;
						font-weight: 300;
						position: relative;
						font-size: 18px;
						margin-bottom: 20px;

						.number {
							display: inline-block;
							font-weight: 900;
							margin-right: 5px;
						}
					}
				}
			}
		}
		.button {
			cursor: pointer;
			position: relative;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: flex;
			-moz-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-moz-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			color: #fff;
			background-color: #d60623;
			border: 1px solid transparent;
			transition: all 0.3s ease;
			width: 420px;
			padding: 16px;
			margin: 0 auto;
			font-size: 24px;
			font-weight: 700;
			border-radius: 7px;
			-webkit-box-shadow: 3px 1px 16px rgb(215 0 0 / 50%);
			box-shadow: 3px 1px 16px rgb(215 0 0 / 50%);
			text-transform: uppercase;
		}
		.button:hover {
			background-color: #8a0315;
		}
		.button:active {
			background-color: #8a0315;
		}
	}
}

@media (max-width: 1240px) {
	.steps {
		padding-top: 24px;
		padding-bottom: 40px;

		.inner {
			max-width: 900px;
			padding: 0 20px;

			.titleContainer {
				margin-bottom: 30px;

				.title {
					font-size: 18px;
				}

				.title::after {
					width: 120px;
				}
			}

			.row {
				max-width: 720px;
				margin-bottom: 40px;

				.col {
					.imgWrapper {
						margin: 0;
					}
					.subtitle {
						font-weight: 500;
						font-size: 18px;
					}

					.list {
						.item {
							font-size: 16px;
							margin-bottom: 10px;

							.number {
								display: inline-block;
								font-weight: 900;
								margin-right: 5px;
							}
						}
					}
				}
			}
			.button {
				width: 260px;
				padding: 11px;
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 768px) {
	.steps {
		background-image: url(../../assets/images/steps/man_bg_s.jpg);
		padding-top: 24px;
		padding-bottom: 40px;

		.inner {
			max-width: 480px;
			padding: 0 10px;

			.titleContainer {
				margin-bottom: 30px;

				.title {
					font-size: 18px;
				}

				.title::after {
					width: 120px;
				}
			}

			.row {
				display: block;
				max-width: 360px;
				margin: 0 auto 24px;

				.col {
					width: auto;
					.imgWrapper {
						margin-bottom: 24px;
					}
					.subtitle {
						font-size: 18px;
					}

					.list {
						.item {
							font-size: 16px;
							margin-bottom: 10px;
						}
					}
				}
			}
			.button {
				width: 240px;
				padding: 11px;
				font-size: 14px;
			}
		}
	}
}
