.container {
	margin: 0 20px;

	.imgBox {
		position: relative;
		cursor: pointer;
		width: 200px;

		.img {
			width: 100%;
			border-radius: 10px;
		}
		.titleContainer {
			position: absolute;
			width: 100%;
			height: 95%;
			top: 0%;
			background: #8080804d;
			border-radius: 10px;

			.title {
				top: 50%;
				left: 50%;
				padding: 10px;
				font-size: 20px;
				position: absolute;
				width: 100%;
				z-index: 9;
				margin: auto;
				transform: translate(-50%, -50%);
				color: #fff;
				font-weight: 500;
				text-align: center;
				text-shadow: 1px 1px 1px #000;
			}
		}
		.titleContainer:hover {
			background: none;
		}
	}
}

@media (max-width: 1240px) {
	.container {
		margin: 0 20px;

		.imgBox {
			width: 124px;

			.titleContainer {
				height: 93%;

				.title {
					font-size: 12px;
				}
			}
			.titleContainer:hover {
				background: none;
			}
		}
	}
}

@media (max-width: 768px) {
	.container {
		margin: 0;

		.imgBox {
			width: auto;

			.titleContainer {
				height: 97%;

				.title {
					font-size: 14px;
				}
			}
			.titleContainer:hover {
				background: none;
			}
		}
	}
}
